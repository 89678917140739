/* eslint-disable */
import React, { createContext, useEffect, useState } from "react";
import Web3Modal from "web3modal";
import { ethers } from "ethers";
import { useDispatch } from "react-redux";
import WalletConnectProvider from "@walletconnect/web3-provider";
import {
  setWalletData,
  setWalletError,
  setWalletDisconnect,
} from "../store/Reducers/Wallet/actions";
import { setAdminData } from "../store/Reducers/AdminPanel/actions";
//
export const WalletWeb3Context = createContext();
export const WalletWeb3Provider = ({ children }) => {
  const dispatch = useDispatch();
  const [web3Modal, setWeb3Modal] = useState(null);
  const [provider, setprovider] = useState(null);
  const [library, setlibrary] = useState(null);
  useEffect(() => {
    const newWeb3Modal = new Web3Modal({
      cacheProvider: true,
      theme: "dark",
      providerOptions: {
        injected: {
          package: WalletConnectProvider,
          options: {
            rpc: {
              56: "https://bsc-mainnet.nodereal.io/v1/64a9df0874fb4a93b9d0a3849de012d3",
              97: "https://data-seed-prebsc-2-s3.binance.org:8545",
            },
          },
        },
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            rpc: {
              56: "https://bsc-mainnet.nodereal.io/v1/64a9df0874fb4a93b9d0a3849de012d3",
              97: "https://data-seed-prebsc-2-s3.binance.org:8545",
            },
          },
        },
      },
    });
    setWeb3Modal(newWeb3Modal);
  }, []);
  ///connect wallet
  async function connectWallet(userWallet) {
    try {
      const providerWeb3 = await web3Modal.connect();
      setprovider(providerWeb3);
      //
      const libraryWeb3 = new ethers.providers.Web3Provider(providerWeb3);
      setlibrary(libraryWeb3);
      //
      const accountsWeb3 = await libraryWeb3.listAccounts();
      const network = await libraryWeb3.getNetwork();
      let walletAddress,
        notUsingDefaultBDWallet = false;
      if (accountsWeb3) {
        walletAddress = accountsWeb3[0];
      }
      if (userWallet === undefined) {
        let localStorageValue = localStorage.getItem("persist:registrobases");
        let walletFromLocalSotrage = JSON.parse(
          JSON.parse(localStorageValue).wallet
        ).user.wallet;
        if (walletAddress !== walletFromLocalSotrage) {
          notUsingDefaultBDWallet = true;
        }
      } else {
        if (walletAddress === userWallet) {
          notUsingDefaultBDWallet = true;
        }
      }
      if (notUsingDefaultBDWallet) {
        alert(
          "Acabas de conectarte con otra billetera digital a la que normalmente lo haces, por favor, vuelve a usar la anterior"
        );
      }
      setAdminData(dispatch, libraryWeb3, walletAddress);
      setWalletData(
        dispatch,
        walletAddress,
        network,
        libraryWeb3,
        userWallet,
        notUsingDefaultBDWallet
      );
    } catch (error) {
      console.log(error);
      setWalletError(dispatch, error.message);
      localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER");
    }
  }
  //disconnect wallet
  async function disconnectWallet() {
    web3Modal.clearCachedProvider();
    setWalletDisconnect(dispatch);
  }

  useEffect(() => {
    if (web3Modal && web3Modal?.cachedProvider) {
      console.log(" web3 connected");
      connectWallet();
    }
  }, [web3Modal]);
  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts) => {
        if (accounts) {
          if (accounts.length === 0) {
            console.log("handleAccountsChanged 0");
            disconnectWallet();
          } else {
            connectWallet();
          }
        }
      };
      const handleChainChanged = (_hexChainId) => {
        console.log("handleChainChanged", _hexChainId);
        disconnectWallet();
      };
      const handleDisconnect = () => {
        console.log("handleDisconnect");
        disconnectWallet();
      };
      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);
      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [provider]);

  return (
    <WalletWeb3Context.Provider
      value={{
        connectWallet,
        disconnectWallet,
        provider,
        library,
        // saleContract,
      }}
    >
      {children}
    </WalletWeb3Context.Provider>
  );
};
