import { create } from "ipfs-http-client";

export const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
export const getRandomBool = () => {
  return Math.random() >= 0.5;
};
//
export const toHex = (num) => {
  const val = Number(num);
  return "0x" + val.toString(16);
};

export const shortenAddress = (address) => {
  if (!address) return "";
  return `${address.slice(0, 5)}...${address.slice(address.length - 4)}`;
};
export const shortenAddressLong = (address) => {
  if (!address) return "";
  return `${address.slice(0, 10)}...${address.slice(address.length - 9)}`;
};
export const formatDateToLocalString = (date) => {
  return new Date(date).toLocaleDateString(undefined, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};
const projectId = "2DRNlUa0eruwh3iEoL1SAEL9gLI";
const projectSecret = "12b613741977963d02069305831da60b";
const auth =
  "Basic " + Buffer.from(projectId + ":" + projectSecret).toString("base64");
export const ipfs = create({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  headers: {
    authorization: auth,
  },
});
