const initialState = {
  isLoadingAdmin: false,
  isAdmin: false,
  error: false,
  onsuccessAdmin: false,
  usersList: false,
};

export const adminPanelReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "ADMIN_LOADING":
      return {
        ...state,
        isLoadingAdmin: true,
        error: false,
        onsuccessAdmin: false,
      };
    case "ADMIN_DATA":
      return {
        ...state,
        isLoadingAdmin: false,
        isAdmin: payload.hasRole,
        error: false,
        onsuccessAdmin: payload.message,
      };
    case "ADMIN_RESET_MESSAGE":
      return {
        ...state,
        onsuccessAdmin: false,
      };

    case "ADMIN_CHECK":
      return {
        ...state,
        onsuccessAdmin: payload.message,
        error: false,
      };
    case "ADMIN_USER_LIST":
      return {
        ...state,
        isLoadingAdmin: false,
        error: false,
        onsuccessAdmin: payload.message,
        usersList: payload.tempArray,
      };
    case "ADMIN_REGISTER":
      return {
        ...state,
        onsuccessAdmin: payload.message,
        error: false,
        isLoadingAdmin: false,
      };

    case "DISCONNECT":
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
