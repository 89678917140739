const initialState = {
  isLoading: false,
  docs: false,
  balance: false,
  backupDocs: false,
  error: false,
  success: false,
  sorteo: false,
  sorteoLoading: false,
};

export const docReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "DOC_LOADING":
      return {
        ...state,
        isLoading: true,
        error: false,
        docs: false,
        success: false,
        sorteoLoading: false,
      };
    case "DOC_SORTEO_LOADING":
      return {
        ...state,
        sorteoLoading: true,
        error: false,
        success: "Generando Sorteo....🎲",
      };
    case "DOC_DATA":
      return {
        ...state,
        isLoading: false,
        error: false,
        docs: payload.tempArray,
        success: payload.message,
      };
    case "DOC_SEARCH_DATA":
      return {
        ...state,
        isLoading: false,
        error: false,
        backupDocs: payload.backupDocs,
        success: payload.message,
      };
    case "DOC_SORTEO":
      return {
        ...state,
        sorteoLoading: false,
        isLoading: false,
        error: false,
        success: payload.message,
        sorteo: payload.sorteo,
      };
    case "DOC_ERROR":
      return {
        ...state,
        isLoading: false,
        balance: false,
        docs: false,
        error: payload,
        success: false,
      };
    case "DOC_CANCEL_REQUEST":
      return {
        ...state,
        isLoading: false,
        sorteoLoading: false,
        error: false,
        success: false,
      };
    case "DOC_SORTEO_RESET":
      return {
        ...state,
        isLoading: false,
        error: false,
        success: false,
        sorteo: false,
        sorteoLoading: false,
      };
    case "DOC_MESSAGE":
      return {
        ...state,
        success: payload,
      };
    case "DOC_BALANCE":
      return {
        ...state,
        balance: payload,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
