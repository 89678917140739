import axios from "axios";
const axiosParams = {
  // baseURL: "https://registro.rodrigoerazo.com/",
  headers: { "cache-control": "no-cache", "Content-Type": "application/json" },
};

export const publicAxios = axios.create(axiosParams);
publicAxios.defaults.headers.common["cache-control"] = "no-cache";
publicAxios.defaults.headers.post["Content-Type"] = "no-cache";
publicAxios.defaults.headers.put["Content-Type"] = "no-cache";

export const privateAxios = axios.create(axiosParams);
privateAxios.defaults.headers.common["cache-control"] = "no-cache";
privateAxios.defaults.headers.post["Content-Type"] = "no-cache";
privateAxios.defaults.headers.put["Content-Type"] = "no-cache";

export const setJWT = (jwt) => {
  privateAxios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
};

export const codeInterceptor = (handler) => {
  privateAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            handler();
            break;
          default:
            console.log(error);
        }
      } else {
        console.log(error);
      }
      return Promise.reject(error);
    }
  ); // end use
};

export const naxios = publicAxios;
export const paxios = privateAxios;
