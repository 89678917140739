import { MainNet, TestNet } from "../../../utils/constants";
import { naxios } from "../../../utils/axios";

export const setWalletData = async (dispatch, walletAddress, network, notUsingDefaultBDWallet) => {
  try {
    dispatch({
      type: "WALLET_LOADING",
    });

    let isWrongNetwork = false;

    let chainId =
      process.env.REACT_APP_NETWORK_ENV === "mainnet"
        ? process.env.REACT_APP_MAINNET_CHAINID
        : process.env.REACT_APP_TESTNET_CHAINID;

    if (network.chainId !== parseInt(chainId)) {
      isWrongNetwork = true;
    }
    dispatch({
      type: "WALLET_DATA",
      payload: {
        walletAddress,
        isWrongNetwork,
      },
    });
  } catch (error) {
    dispatch({
      type: "WALLET_ERROR",
      payload: error,
    });
  }
};
export const setWalletNetwork = async (dispatch, library) => {
  try {
    dispatch({
      type: "WALLET_LOADING",
    });
    await library.provider.request({
      method: "wallet_addEthereumChain",
      params: [
        process.env.REACT_APP_NETWORK_ENV === "mainnet" ? MainNet : TestNet,
      ],
    });
  } catch (error) {
    dispatch({
      type: "WALLET_ERROR",
      payload: error.message,
    });
  }
};
export const setWalletDisconnect = async (dispatch) => {
  dispatch({
    type: "DISCONNECT_WALLET",
  });
};
export const setWalletError = async (dispatch, error) => {
  dispatch({
    type: "WALLET_ERROR",
    payload: error,
  });
};
export const setEmailLogin = async (dispatch, dataform) => {
  try {
    dispatch({
      type: "WALLET_LOADING",
    });
    const { data } = await naxios.post("/correos/login", {
      correo: dataform.email,
    });
    console.log(data);

    let message =
      "Espera unos segundos, estamos enviando un código de verificación a tu correo ⏲️";
    let email = dataform.email;
    dispatch({
      type: "WALLET_EMAIL",
      payload: {
        message,
        email,
      },
    });
  } catch (error) {
    console.log(error);
    if (error?.request?.responseText) {
      let response = JSON.parse(error.request.responseText);
      dispatch({
        type: "WALLET_ERROR",
        payload: response.message,
      });
    } else {
      dispatch({
        type: "WALLET_ERROR",
        payload: "Error con el server ❌",
      });
    }
  }
};
export const setEmailVerify = async (dispatch, email, dataform) => {
  try {
    dispatch({
      type: "WALLET_LOADING",
    });
    const { data } = await naxios.post("/correos/validatetoken", {
      correo: email,
      authCode: dataform.authCode,
    });
    console.log(data);
    let message = data.message;
    let user = data.data;
    delete user.correo;
    dispatch({
      type: "WALLET_VERIFY",
      payload: {
        isLogged: true,
        user: user,
        message,
      },
    });
  } catch (error) {
    if (error?.request?.responseText) {
      let response = JSON.parse(error.request.responseText);
      dispatch({
        type: "WALLET_ERROR",
        payload: response.message,
      });
    } else {
      dispatch({
        type: "WALLET_ERROR",
        payload: "Error verificando codigo ❌",
      });
    }
  }
};
export const setEmailRegister = async (dispatch, dataform) => {
  try {
    dispatch({
      type: "WALLET_LOADING",
    });
    const { data } = await naxios.post("/correos/signup", {
      correo: dataform.email,
      nombre: dataform.nombre,
      type: dataform.type,
      wallet: dataform.wallet,
      empresa: dataform.particular,
      referallFriend: dataform.code,
    });
    console.log(data)
    let message = "Nos pondremos en contacto contigo lo antes posible via correelectronico. ✔️";
    dispatch({
      type: "WALLET_REGISTER",
      payload: {
        message,
        data,
      },
    });
  } catch (error) {
    console.log(error);
    if (error?.request?.responseText) {
      let response = JSON.parse(error.request.responseText);
      dispatch({
        type: "WALLET_ERROR",
        payload: response.message,
      });
    } else {
      dispatch({
        type: "WALLET_ERROR",
        payload: "Error con el server ❌",
      });
    }
  }
};
export const contactForm = async (dispatch, dataform) => {
  try {
    dispatch({
      type: "WALLET_LOADING",
    });
    console.log(dataform);
    const { data } = await naxios.post("/correos/contacto", {
      correo: dataform.email,
      empresa: dataform.nombre,
      telefono: dataform.tel,
      asunto: dataform.asunto,
    });
    console.log(data);
    let message = "Mensaje ha sido enviado ✔️";
    dispatch({
      type: "WALLET_REGISTER",
      payload: {
        message,
        data,
      },
    });
  } catch (error) {
    console.log(error);
    if (error?.request?.responseText) {
      let response = JSON.parse(error.request.responseText);
      dispatch({
        type: "WALLET_ERROR",
        payload: response.message,
      });
    } else {
      dispatch({
        type: "WALLET_ERROR",
        payload: "Error con el server ❌",
      });
    }
  }
};
export const newsletterForm = async (dispatch, email) => {
  try {
    dispatch({
      type: "WALLET_LOADING",
    });
    const { data } = await naxios.post("/correos/subscription", {
      correo: email,
    });
    console.log(data);
    let message = "Correo Agregado ✔️";
    dispatch({
      type: "WALLET_REGISTER",
      payload: {
        message,
        data,
      },
    });
  } catch (error) {
    console.log(error);
    if (error?.request?.responseText) {
      let response = JSON.parse(error.request.responseText);
      dispatch({
        type: "WALLET_ERROR",
        payload: response.message,
      });
    } else {
      dispatch({
        type: "WALLET_ERROR",
        payload: "Error con el server ❌",
      });
    }
  }
};
export const logout = async (dispatch) => {
  dispatch({
    type: "DISCONNECT",
  });
};
