import { Contract, ethers } from "ethers";

import {
  contractTokenAddress,
  tokenABI,
  ZERO_ADDRESS,
} from "../../../utils/constants";
import { paxios } from "../../../utils/axios";

export const setAdminData = async (dispatch, library, walletAddress) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    let signer = library.getSigner();
    let tokenContract = new Contract(contractTokenAddress, tokenABI, signer);
    const hasRole = await tokenContract.hasRole(ZERO_ADDRESS, walletAddress);
    // const hasRole = true
    let message = hasRole ? "Data Cargada ✅" : "No eres Admin ❌";
    dispatch({
      type: "ADMIN_DATA",
      payload: { hasRole, message },
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: "ADMIN_ERROR",
      payload: "Error Generico desde Metamask",
    });
  }
};

export const setAdminRegister = async (
  dispatch,
  library,
  dataform,
  walletAddress
) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    let signer = library.getSigner();
    let tokenContract = new Contract(contractTokenAddress, tokenABI, signer);
    const montoDeDocumentos = ethers.utils
      .parseEther(dataform.docs.toString())
      .toString();
    const costoGas = ethers.utils
      .parseEther(dataform.coste.toString())
      .toString();

    const registerAnUser = await tokenContract.registerAnUser(
      montoDeDocumentos,
      dataform.wallet,
      { from: walletAddress, value: costoGas }
    );
    let message = "Verificando, Por favor espera";
    dispatch({
      type: "ADMIN_CHECK",
      payload: { message },
    });
    const response = await registerAnUser.wait();
    message = "Registro exitoso ✅";
    dispatch({
      type: "ADMIN_REGISTER",
      payload: { response, message },
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: "ADMIN_ERROR",
      payload: "Error Generico desde Metamask",
    });
  }
};
export const fetchUsers = async (dispatch, library) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    //------------------------
    let tempArray = [];
    let signer = library.getSigner();
    let tokenContract = new Contract(contractTokenAddress, tokenABI, signer);

    const { data } = await paxios.get("/correos/getall");
    tempArray = data.data;
    await Promise.all(
      tempArray.map(async (item) => {
        if (item.wallet !== "") {
          const balanceOf = await tokenContract.balanceOf(item.wallet);
          item.balance = parseInt(
            ethers.utils.formatEther(balanceOf.toString())
          );
        } else {
          item.balance = "0";
        }
        return item;
      })
    );
    let message = "Lista de usuarios cargada exitosamente 🎉";
    dispatch({
      type: "ADMIN_USER_LIST",
      payload: { tempArray, message },
    });
    //------------------------
  } catch (error) {
    console.log(error);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error al cargar la data, intente nuevamente 🙁",
      });
    }
  }
};
export const verifyUser = async (dispatch, email) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    //------------------------
    const { data } = await paxios.post("/correos/verified", {
      correo: email,
    });

    let message = "Usuario Verificado 🎉";
    dispatch({
      type: "ADMIN_CHECK",
      payload: { data, message },
    });
    fetchUsers(dispatch);
    //------------------------
  } catch (error) {
    console.log(error);
    fetchUsers(dispatch);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error al cargar la data, intente nuevamente 🙁",
      });
    }
  }
};
////RESET MESSAGE
export const resetMessage = async (dispatch) => {
  dispatch({
    type: "ADMIN_RESET_MESSAGE",
  });
};
export const updateUser = async (dispatch, row, dataform) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    //------------------------
    const { data } = await paxios.put("/correos/update", {
      _id: row._id,
      correo: dataform.email,
      wallet: dataform.wallet,
      nombre: dataform.nombre,
      rol: dataform.rol,
    });
    let message = `Usuario Actualizado: ${dataform.email} 🎉`;
    dispatch({
      type: "ADMIN_REGISTER",
      payload: { data, message },
    });
    //------------------------
  } catch (error) {
    console.log(error);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error al cargar la data, intente nuevamente 🙁",
      });
    }
  }
};
