import React, { Suspense, useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Spinner from "./components/Spinner";
import Modal from "./components/Modal";
import WrongNetwork from "./components//WrongNetwork";
import ScrollTop from ".//utils/ScrollTop";
import { WalletWeb3Provider } from "./context/WalletWeb3Context";
import { useSelector } from "react-redux";
import { shortenAddressLong } from "./utils";
import { codeInterceptor } from "./utils//axios";
import { useDispatch } from "react-redux";

//
const Home = React.lazy(() => import("./pages/Home"));
const Documentos = React.lazy(() => import("./pages/Documentos"));
const Register = React.lazy(() => import("./pages/Register"));
const Create = React.lazy(() => import("./pages/Create"));
const List = React.lazy(() => import("./pages/List"));
const Cancel = React.lazy(() => import("./pages/Cancel"));
const Conditions = React.lazy(() => import("./pages/Conditions"));
const Privacy = React.lazy(() => import("./pages/Privacy"));
const Cookies = React.lazy(() => import("./pages/Cookies"));
const Tarifas = React.lazy(() => import("./pages/Tarifas"));
const Contact = React.lazy(() => import("./pages/Contact"));
const ComoEmpezar = React.lazy(() => import("./pages/ComoEmpezar"));
const Login = React.lazy(() => import("./pages/Login"));
const AdminPanel = React.lazy(() => import("./pages//AdminPanel"));
const Outdated = React.lazy(() => import("./pages/Outdated"));

const App = () => {
  const dispatch = useDispatch();

  const [isModalWrongNetworkOpen, setisModalWrongNetworkOpen] = useState(false);
  const { isWrongNetwork, walletAddress } = useSelector(({ wallet }) => wallet);
  useEffect(() => {
    if (isWrongNetwork) {
      setisModalWrongNetworkOpen(() => true);
    } else {
      wrongnetworkModalClose();
    }
  }, [isWrongNetwork]);
  useEffect(() => {
    codeInterceptor(() => {
      dispatch({
        type: "DISCONNECT",
      });
    }, [dispatch]);
  });
  const wrongnetworkModalClose = () => {
    setisModalWrongNetworkOpen(() => false);
  };
  return (
    <Suspense fallback={<Spinner full>Loading</Spinner>}>
      <BrowserRouter>
        <WalletWeb3Provider>
          {!!walletAddress && (
            <Modal
              visible={isModalWrongNetworkOpen}
              onClose={() => wrongnetworkModalClose()}
            >
              <WrongNetwork account={shortenAddressLong(walletAddress)} />
            </Modal>
          )}
          <ScrollTop>
            <Routes>
              <Route path="/" element={<Home />} exact />
              <Route path="/search" element={<Documentos />} exact />
              <Route path="/register" element={<Register />} exact />
              <Route path="/conditions" element={<Conditions />} exact />
              <Route path="/cookies" element={<Cookies />} exact />
              <Route path="/privacy" element={<Privacy />} exact />
              <Route path="/tarifas" element={<Tarifas />} exact />
              <Route path="/contact" element={<Contact />} exact />
              <Route path="/como" element={<ComoEmpezar />} exact />
              <Route path="/login" element={<Login />} exact />
              <Route path="/create" element={<Create />} exact />
              <Route path="/admin" element={<AdminPanel />} exact />
              <Route path="/list" element={<List />} exact />
              <Route path="/cancel" element={<Cancel />} exact />
              <Route path="/outdated" element={<Outdated />} exact />
              {/* ///////////////////////////// */}
              <Route path="*" element={<Navigate to={"/"} />} />
            </Routes>
          </ScrollTop>
        </WalletWeb3Provider>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
